import { Link } from 'react-router-dom';
import Footer from '../shared/footer';
// import Header from '../shared/header';
import Sticky from '../shared/sticky';
import { useState } from 'react';
import axios from 'axios';
import cls from 'classnames';
import ContactForm from '../shared/contactform';

export default function Contact() {

    return (
        <>
            {/* <Header /> */}

            <Sticky  selectedLink="kontakt" />

            <main>
                <div className="wider">
                    <div className="testmetodcontent">
                        <div className="detailheader">
                            {/* <Link to="/" className="backbtn" >
                                <span>Startsidan</span>
                            </Link> */}

                            <h1>Kontakt</h1>
                        </div>

                        <ContactForm />

                    </div>
                </div>
            </main>

            <Footer />
        </>
    );
}


