import React, { Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import useScript from "./utils/useScript";
import Contact from "./views/contact";
import Home from "./views/home";
import TestMetod from "./views/testmetoddetail";
import WhoNeedsAccessibility from "./views/whoneedsaccessibility";
import { HelmetProvider } from "react-helmet-async";

const cookieBotProperties = {
  id: "Cookiebot",
  "data-blockingmode": "auto",
  type: "text/javascript",
  "data-cbid": "ced5495f-4f5b-46a5-8856-ec931f8cfbc3",
};

function App() {
  useScript("https://consent.cookiebot.com/uc.js", cookieBotProperties);
  return (
    <Router>
      <HelmetProvider>
        <Fragment>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/testmetod" exact component={TestMetod} />
            <Route path="/kontakt" exact component={Contact} />
            <Route path="/vem-berors-av-tillgangligheten" exact component={WhoNeedsAccessibility} />
          </Switch>
        </Fragment>
      </HelmetProvider>
    </Router>
  );
}

export default App;
