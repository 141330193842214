
import FilterArea from "./filterarea";
import Main from "./main";
import NumbersFirst from "./numbers-first";
import Footer from "../shared/footer";
import { useState } from "react";
import { useInView } from "react-intersection-observer";
import ContactForm from '../shared/contactform';
import Sticky from "../shared/sticky";

export default function HomePage() {
  const [contactRef, contactInView] = useInView();
  const [resultatRef, resultatInView, resultatEntry] = useInView();
  const [site, setSite] = useState();

  const selectedLink = contactInView
    ? "kontakt"
    : resultatInView
    ? "resultat"
    : undefined;

  return (
    <>
      {/* <Header /> */}

      <Sticky selectedLink={selectedLink} />

      <main>
        <Main />

        <div id="resultat" ref={resultatRef}>
          <FilterArea setSite={setSite} />
        </div>

        <div id="kontakt" ref={contactRef}>
          <ContactForm site={site} />
        </div>
      </main>

      <Footer />
    </>
  );
}
