import { useEffect } from 'react';

const useScript = (url, properties) => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    if (properties) {
      Object.keys(properties).forEach(key => {
        script.setAttribute(key, properties[key]);
      }
      );
    }
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    }
  }, [url]);
};

export default useScript;