import { Link } from "react-router-dom";
import Footer from "../shared/footer";
// import Header from '../shared/header';
import Sticky from "../shared/sticky";
import { useState, useEffect } from "react";
import axios from "axios";
import cls from "classnames";

function getCaptcha() {
  return new Promise((resolve, reject) => {
    let gc = window.grecaptcha;
    let sitekey = "6Lf54iIcAAAAAKcdrYi7AJtJkkULNYpV4_wYTq2w";

    if (!gc) {
      return reject("recaptcha error");
    }

    gc.ready(function () {
      gc.execute(sitekey, { action: "submit" }).then(function (token) {
        resolve(token);
      });
    });
  });
}

export default function HubspotContactForm({ site }) {
  const [form, setForm] = useState();

  useEffect(() => {
    if (!form || !site) {
      return;
    }

    form.querySelector("input[name=barometer_interested_site]").value = site.siteId;
    form.querySelector("input[name=barometer_interested_site_name]").value = site.siteName;
  }, [form, site]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "20148360",
          formId: "2b777bf5-6910-4008-9e15-76cde67604cc",
          target: "#hubspotForm",
          onFormReady($form) {
            setForm($form);
          },
        });
      }
    });
  }, []);

  return (
    <section>
      <div className="wider">
        <div className="standardbody">
          <div id="hubspotForm">Loading...</div>
        </div>
      </div>
    </section>
  );
}

export function ContactForm() {
  const [values, setValues] = useState({});
  const [result, setResult] = useState("");
  const [busy, setBusy] = useState(false);
  const [focusedElement, setFocusedElement] = useState("");

  const [cb1, setCb1] = useState(false);
  const [showErrors, setShowErros] = useState(false);
  const [cb2, setCb2] = useState(false);

  const fields = [
    {
      key: "name",
      ac: "given-name",
      title: "Förnamn",
      placeholder: "",
      required: { message: "Vänligen skriv in ditt förnamn." },
    },
    {
      key: "lastname",
      ac: "family-name",
      title: "Efternamn",
      placeholder: "",
      required: { message: "Vänligen skriv in ditt efternamn." },
    },
    {
      key: "phone",
      ac: "tel",
      title: "Telefonnummer",
      placeholder: "",
      required: { message: "Vänligen skriv in ditt telefonnummer." },
      pattern: { regex: /^[\d-()+ ]*$/, message: "Telefonnummer är ogiltigt." },
    },
    {
      key: "email",
      ac: "email",
      title: "E-post",
      placeholder: "",
      required: { message: "Vänligen skriv in din e-postadress." },
      pattern: {
        regex: /[^@]+@[^@]+\.[^@]+/,
        message:
          "Mejladressen skrevs i fel format. Använd detta format: name@company.com",
      },
    },
    {
      key: "company",
      ac: "organization",
      title: "Organisation",
      required: { message: "Vänligen skriv in din organisation." },
      placeholder: "",
    },
    {
      key: "message",
      title: "Meddelande (Valfritt)",
      required: false,
      placeholder: "",
      multiline: true,
    },
  ];

  function handleChangeValue(key, value) {
    setValues({ ...values, [key]: { value, error: undefined } });
  }

  async function handleSubmit() {
    setShowErros(true);
    let v = { ...values };
    let form = { source: "inkluderings­barometer", newsletter: cb2 };
    let valid = cb1;
    let invalidField;
    fields.forEach((f) => {
      let value = values[f.key]?.value || "";

      if (f.required && !value) {
        v[f.key] = { value, error: f.required.message };
        valid = false;
        if (!invalidField) invalidField = f.key;
      } else if (f.pattern && !f.pattern.regex.test(value)) {
        v[f.key] = { value, error: f.pattern.message };
        valid = false;
        if (!invalidField) invalidField = f.key;
      } else {
        form[f.key] = value;
      }
    });

    if (invalidField) {
      document.getElementById(invalidField).focus();
    }
    if (!valid) {
      setValues(v);
    } else {
      setBusy(true);
      try {
        let captcha = await getCaptcha();
        await axios.post("/api/contact", form, { headers: { captcha } });
        setValues({});
        setResult("Tack! Vi återkommer så snart som möjligt.");
      } catch (e) {
        setResult(e.toString());
      } finally {
        setBusy(false);
      }
    }
  }

  return (
    <section id="contactcontent" className="contactarea">
      <div className="wider">
        <div className="contactcol">
          <h2> Är digital inkludering viktigt för dig?</h2>
          <p>Ta bort hinder och skapa en bättre användarupplevelse för alla.</p>
          <p>Vi hjälper dig! Kontakta oss så berättar vi mer.</p>
          <div className="contactform forminc">
            {fields.map((f) => (
              <label htmlFor={f.key} key={f.key} className={f.key}>
                <span>{f.title}</span>
                {values[f.key]?.error && (
                  <div className="error">
                    <img src="./images/error.svg" alt="" aria-hidden="true" />{" "}
                    {values[f.key].error}
                  </div>
                )}

                <div
                  className={cls("filterbox", {
                    active: f.key === focusedElement,
                    unactive: f.key !== focusedElement,
                  })}
                >
                  {f.multiline ? (
                    <textarea
                      onFocus={() => setFocusedElement(f.key)}
                      onBlur={() => setFocusedElement("")}
                      disabled={busy}
                      value={values[f.key]?.value || ""}
                      onChange={(e) => handleChangeValue(f.key, e.target.value)}
                      name=""
                      id={f.key}
                      rows="4"
                      className="activator"
                    ></textarea>
                  ) : (
                    <input
                      autoComplete={f.ac}
                      onFocus={() => setFocusedElement(f.key)}
                      onBlur={() => setFocusedElement("")}
                      disabled={busy}
                      value={values[f.key]?.value || ""}
                      onChange={(e) => handleChangeValue(f.key, e.target.value)}
                      type="text"
                      id={f.key}
                      placeholder={f.placeholder}
                      className="activator"
                      {...(f.required ? { "aria-required": true } : {})}
                    />
                  )}
                </div>
              </label>
            ))}

            <div className="checks">
              <label className="container-checkbox">
                Ja, Funka får ta del av min personliga information
                <input
                  checked={cb1}
                  onChange={(e) => setCb1(e.target.checked)}
                  type="checkbox"
                  aria-required="true"
                ></input>
                <span className="checkmark"></span>
                {showErrors && !cb1 ? (
                  <span className="error">
                    <img src="./images/error.svg" alt="" aria-hidden="true" />{" "}
                    Du behöver godkänna att vi får ta del av din personliga
                    information.
                  </span>
                ) : undefined}
              </label>

              <label className="container-checkbox">
                Ja, jag vill prenumerera på Funkas nyhetsbrev (Valfritt)
                <input
                  checked={cb2}
                  onChange={(e) => setCb2(e.target.checked)}
                  type="checkbox"
                ></input>
                <span className="checkmark"></span>
              </label>
            </div>
          </div>
          <div className="btnalign">
            <button
              disabled={busy}
              className="genericbtn"
              onClick={handleSubmit}
            >
              <span>Skicka</span>
            </button>
            <div className="formresults">{result}</div>
          </div>
        </div>
      </div>
    </section>
  );
}
