import baro from '../../data/baro.json';
import stats from '../../data/stats.json';
import Counter from '../shared/counter';

const sectors = new Set(baro.map(b => b[0]));
const sectorCount = Array.from(sectors).map(sector => ({ sector, count: baro.filter(b => b[0] === sector).length }));

const sectorLimit = 7; // if number of items is smaller than this number, put it under other.


export default function Main() {
    return (
        <section id="main">
            <div className="wider" >
                <div className="maincontent" id="main-content">
                    <h1>Välkommen till Funkas Inkluderingsbarometer 2023</h1>
                    <p>Funkas Inkluderingsbarometer syftar till att skapa en gemensam bild av hur den digitala tillgängligheten mognar och prioriteras inom offentlig sektor. Målet är att ge verksamheterna inom sektorn ett verktyg för att se både hur de själva utvecklas, och hur de utvecklas i förhållande till andra.</p>
                    <p>Genom att synliggöra goda exempel skapas förutsättningar för nätverkande och lärande. Det hjälper oss att skapa ett mer digitalt tillgängligt och inkluderande samhälle. Faktum är att digital offentlig service måste vara tillgänglig för alla oavsett förmåga för att undvika digitalt utanförskap.</p>

                    <h2>
                        Vad blev <span className="orange">resultatet?</span>
                    </h2>

                    <p className="mainp">Årets rapport visar att endast <strong>{stats.totalSiteCount - stats.failedSiteCount}</strong> av <strong>{stats.totalSiteCount}</strong> webbplatser klarade de utvalda testerna</p>

                    <div className="filterheader">
                        <h2><span>{(stats.failedSiteCount / stats.totalSiteCount * 100).toFixed(2).replace('.', ',')}% av webbplatserna</span><br />
                            <span className="orange">har tillgänglighetsfel</span>
                        </h2>
                    </div>
                </div>
            </div>
        </section>
    );
}