import data from "../../data/testmetod2.json";
// import { Link } from "react-router-dom";

export default function Testtable() {
  return (
    <section>
      <div className="wider">
        {/* <h2>Testmetod</h2> */}

        <div className="standardbody wnacontent">
          <div className="parawider">
            <div className="altersec">
              <div className="text">
                <p>
                  Webbplatsernas startsida är testade utifrån 19 testpunkter
                  baserade på gällande lagkrav för offentlig sektor. De testade
                  webbplatserna kan innehålla andra tillgänglighetsproblem som
                  vi inte har testat i den här undersökningen. Syftet med
                  inkluderingsbarometern är att uppmärksamma och skynda på den
                  digitala inkluderingen i samhället.
                </p>
                <p>
                  De 19 utvalda testerna har utförts på respektive webbplats
                  startsida. WCAG 2.1 nivå A och AA innehåller 50 riktlinjer, så
                  detta är stickprov från de 50 riktlinjerna. Testerna är
                  utvalda för att ge en så bra genomsnittlig bild som möjligt av
                  tillgänglighet på en startsida, baserat på vad som kan mätas
                  korrekt med automatik. Testerna täcker in användare med olika
                  förmågor, samt användning med och utan hjälpmedel.
                </p>
              </div>
              <img
                style={{ float: "right" }}
                src="./images/figurine-04.svg"
                alt=""
                aria-hidden="true"
              />
            </div>
            <h2>Testpunkter</h2>
            {data.map((d) => (
              <div key={d.id}>
                <div className="testmetod-item">
                  <h3>{d.title}</h3>
                  <p>{d.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
