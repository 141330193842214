import stats from '../../data/stats.json';

import React, { useMemo, useState } from 'react';
import baro from '../../data/baro.json';
import cls from 'classnames';


function normalize(n) {
    return n.toLowerCase().replace(/[^a-z0-9]/g, function (m) {
        return {
            'ö': 'o',
            'ä': 'a',
            'å': 'a'
        }[m] || m;
    });
}

const locations = Array.from(new Set(baro.map(b => b[2]))).sort((a, b) => a.localeCompare(b, 'sv', { sensitivity: 'base' }));
const types = Array.from(new Set(baro.map(b => b[3]))).sort((a, b) => a.localeCompare(b, 'sv', { sensitivity: 'base' }));


const _baro2 = (function () {
    const _baro2 = [];
    const calculateScore = (errorCount, occuranceCount) => errorCount * 250 + occuranceCount;

    for (let b of baro) {
        let [
            name, url, location, segment,
            errors, occurance, fileId
        ] = b;
        let normalizedUrl = url.match('^(http|https)://([a-zA-Z0-9.-]*)(/.*)?')[2];

        let normalized = normalize(name) + ' ' + normalizedUrl;
        const score = calculateScore(errors, occurance);

        _baro2.push({ segment, location, name, score, url, normalized, pos: 1, errors, occurance, fileId });
    }

    _baro2.sort((a, b) => {
        if (a.score === b.score) return a.name.localeCompare(b.name, 'sv', { sensitivity: 'base' });
        return a.score - b.score;
    })

    let lastPos = 0;
    let lastScore = -1;
    for (let b of _baro2) {
        if (b.score !== lastScore) {
            lastPos++;
            lastScore = b.score;
        }
        b.pos = lastPos;
    }

    return _baro2;
}());

const pagesize = 100;


export default function FilterArea({ setSite }) {

    const [orderBy, _setOrderBy] = useState('pos');
    const [name, setName] = useState('');
    const [location, _setLocation] = useState('');
    const [type, setType] = useState('');
    const [limit, setLimit] = useState(pagesize);
    const [focusedElement, setFocusedElement] = useState('');

    const normalizedName = normalize(name);

    function applyFilter(b) {
        if (name && !b.normalized.includes(normalizedName)) return false;
        if (location && b.location !== location) return false;
        if (type && b.segment !== type) return false;
        return true;
    }

    function setOrderBy(key) {
        if (orderBy === key) {
            _setOrderBy('-' + key);
        } else {
            _setOrderBy(key);
        }
    }

    let baro2 = useMemo(() => {
        let clone = [..._baro2];
        let dir = 1;
        let key = orderBy;
        if (orderBy.startsWith('-')) {
            dir = -1;
            key = orderBy.substring(1);
        }
        clone.sort((A, B) => {
            let a = A[key];
            let b = B[key];
            if (a === b) return 0;
            if (a > b) return dir;
            return dir * -1;
        });
        return clone;
    }, [orderBy]);

    const filtered = baro2.filter(applyFilter);
    const hasMore = filtered.length > limit;

    const filteredTypes = location
        ? Array.from(new Set(baro2.filter(b => b.location === location).map(b => b.segment)))
        : types;

    function setLocation(l) {
        _setLocation(l);
        setType('');
    }

    function handleLoadMore() {
        setLimit(limit + pagesize);
    }

    function getChangeText(pos, prevPos) {
        if (!prevPos) return <span className='diff new' aria-label='Ny ranking'>NY</span>;
        if (pos === prevPos) return <span className='diff same' aria-label='Samma ranking'>SAMMA</span>;
        if (pos < prevPos) return <span className='diff up' aria-label={(prevPos - pos) + ' placeringar bättre i ranking'}>+{prevPos - pos}</span>
        return <span className='diff down' aria-label={(pos - prevPos) + ' placeringar sämre i ranking'}>-{pos - prevPos}</span>
    }

    return (
        <section id="filter">
            <div className="wider">
                <div className="filtercontent">
                    <div className="standardbody filterbody">
                        <div className="filtertext">
                            <p className="filterp">Här nedan kan du söka och filtrera för att hitta de testade organisationerna i Funkas Inkluderingsbarometer.</p>
                            <p className="filterp">Inkluderingsbarometern väger samman typ av fel med antalet gånger de återfinns på webbplatsens startsida. Därefter rankas alla testade webbplatser utifrån det sammanvägda resultatet. Undersökningen genomfördes under vecka 38 2023 med hjälp av utvalda automatiska tester baserade på Axe-cores tillgänglighetstester.</p>
                        </div>

                        <div className="filterall">
                            <fieldset className="forminc">
                                <legend className="header">Sök bland resultaten</legend>
                                <div className="searchbody">
                                    <label htmlFor="search">
                                        <span>Organisationsnamn</span>
                                        <div className={cls('filterbox', { active: 'search' === focusedElement, unactive: 'search' !== focusedElement })}>
                                            <img src="./images/search.svg" alt="" aria-hidden="true" />
                                            <input
                                                onFocus={() => setFocusedElement('search')}
                                                onBlur={() => setFocusedElement('')}
                                                value={name}
                                                onChange={e => { setName(e.target.value); setLimit(pagesize) }} type="text" id="search" className="activator" />
                                        </div>
                                    </label>
                                </div>
                            </fieldset>
                            <div className="seperator">
                                <div className="header">eller</div>
                                <span></span>
                            </div>
                            <fieldset className="dropdown">
                                <legend className="header">Filtrera resultaten</legend>
                                <div className="dropbody">
                                    <label htmlFor="location">
                                        <span>Plats</span>
                                        <div className="filterbox">
                                            <select type="text" value={location} onChange={e => setLocation(e.target.value)} className="activator" id="location"  >
                                                <option value="">Alla</option>
                                                {locations.map(l => <option value={l} key={l}>{l}</option>)}
                                            </select>
                                        </div>
                                    </label>

                                    <label htmlFor="type">
                                        <span>Typ av organisation</span>
                                        <div className="filterbox">
                                            <select type="text" value={type} onChange={e => setType(e.target.value)} className="activator" id="type" >
                                                <option value="">Alla</option>
                                                {filteredTypes.map(l => <option value={l} key={l}>{l}</option>)}
                                            </select>
                                        </div>
                                    </label>
                                </div>
                            </fieldset>
                        </div>




                        <div className="tablecon" id="tablecon">
                            <table className="filtertable">
                                <caption className="filtertableheader">Topplista <span aria-live="polite" aria-atomic="true">Visar {Math.min(limit, filtered.length)} av {filtered.length}</span></caption>
                                <thead>
                                    <tr>
                                        <th className="num" scope="col"><button style={{ cursor: 'pointer' }} onClick={() => setOrderBy('pos')}>Ranking #
                                            <img src="./images/sort.svg" alt="" aria-hidden="true" className='sortico' /></button></th>
                                        <th className="comp" scope="col">Organisationsnamn och webbplats</th>
                                        <th className="city" scope="col">Plats</th>
                                        <th className="errors" scope="col">Underkända tester</th>
                                        <th className="occurances" scope="col">Förekomster underkända tester</th>
                                        <th className='send-results' scope="col">Info</th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {filtered.length === 0 ?
                                        <tr className="emptystatetr" >
                                            <td colspan="8">
                                                <div className="emptystate">Inga resultat matchar dina sökkriterier.</div>

                                            </td></tr>
                                        : (function () {
                                            let lastRank = 0;
                                            return filtered.slice(0, limit).map((b) => {

                                                let { segment, location, name, url, pos, prevPos, errors, occurance, otherErrors, otherOccurances, fileId } = b;

                                                let row = <tr>
                                                    <td className="num">
                                                        <span className='ranking'>
                                                            <span className='pos'>
                                                                {pos === 1 && <img src="./images/trophy.svg" role="presentation" alt="" />}
                                                                {pos === 2 && <img src="./images/trophy-2.svg" role="presentation" alt="" />}
                                                                {pos === 3 && <img src="./images/trophy-3.svg" role="presentation" alt="" />}

                                                                {pos}</span>
                                                        </span>
                                                    </td>
                                                    <td className="comp">
                                                        <span className="compname">{name}</span>
                                                        <span className="sitename"><a href={url} target="_blank" rel="noreferrer">{url}</a></span>
                                                    </td>
                                                    <td className="city">{location}</td>
                                                    <td className="errors"><span className="mobileonly">Underkända tester: </span>  <span>{errors}</span></td>
                                                    <td className="occurances"><span className="mobileonly">Förekomster underkända tester: </span>  <span>{occurance}</span></td>
                                                    <td className="send-results"><a href="#kontakt" onClick={() => setSite({ siteId: `https://funka.blob.core.windows.net/barometer/2023/results/${fileId}/report.html`, siteName: name })}>Få rapport</a></td>
                                                </tr>;

                                                let rankHeader = undefined;
                                                if (lastRank !== pos && orderBy === 'pos') {
                                                    lastRank = pos;
                                                    let sitesWithSameRank = filtered.filter(f => f.pos === pos).length;
                                                    if (sitesWithSameRank > 1) rankHeader = <tr className='grouping-header-tr'>
                                                        <td colSpan={7}><span className="grouping-header">
                                                            Följande <strong>{sitesWithSameRank}</strong> webbplatser har placering <strong>#{pos}</strong></span>
                                                        </td>
                                                    </tr>
                                                }
                                                return <React.Fragment key={name}>{rankHeader}{row}</React.Fragment>

                                            })
                                        }())}
                                </tbody>


                            </table>
                            <div className="tfoot">
                                <div className="tablefooter">
                                    <div>Visar {Math.min(limit, filtered.length)} av {filtered.length}</div>
                                    <div>{limit < filtered.length && <button id="loadmorebtn" onClick={handleLoadMore} className="loadmore">Visa {pagesize} fler</button>}</div>
                                    <div>{limit > pagesize && <button className="collapse" onClick={(e) => { setLimit(pagesize); setTimeout(() => document.getElementById('loadmorebtn').focus(), 50) }}>Minimera lista</button>}</div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    );
}