import cls from "classnames";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";

export default function Sticky({ selectedLink }) {
  const [height, setHeight] = useState(0);
  const [scrollY, setScrollY] = useState(0);

  const [menuOpen, setMenuOpen] = useState(false);

  function handleScroll(e) {
    setHeight(window.innerHeight);
    setScrollY(window.scrollY);
  }

  function handleKey(e) {
    if (e.key === "Escape") {
      setMenuOpen(false);
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleScroll);
    window.addEventListener("keydown", handleKey);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleScroll);
      window.removeEventListener("keydown", handleKey);
    };
  }, []);

  const open = scrollY > height / 20;

  let mainlinks = [
    { id: "resultat", title: "Resultat", href: "/#resultat" },
    { id: "testmetod", title: "Testmetod", href: "/testmetod" },
    {
      id: "vemberors",
      title: "Vem berörs?",
      href: "/vem-berors-av-tillgangligheten",
    },
    { id: "kontakt", title: "Kontakt", href: "/kontakt" },
  ];

  const title =
    {
      resultat: "Resultat",
      testmetod: "Testmetod",
      vemberors: "Vem berörs?",
      kontakt: "Kontakt",
    }[selectedLink] ?? "Home";

  // return <div className={cls('sticky', { open })}>window.height={height} / scrollY:{scrollY} / open: {open ? 'yes' : 'no'}</div>
  return (
    <header>
      <Helmet>
        <title>{title} - Funkas Inkluderingbarometer 2023</title>
      </Helmet>
      <div id="skiplinks">
        <a
          href={window.location.pathname + "#main-content"}
          className="skip-to-main-content-link"
        >
          Till innehåll på sidan
        </a>
      </div>
      <div className={cls("sticky", { open })}>
        <div className="wider navcon">
          <div className="headercon">
            <a href="/" className="logo">
              <img
                src="./images/Funka_logotyp.svg"
                alt="Funkas logotyp - tillbaka till startsidan."
              />
            </a>
            {/* <div className="hambtn mobileonly" role="menu"><img src="./images/ham.svg" alt="Menu button" aria-label="öppnar meny" /></div> */}

            <button
              onClick={() => setMenuOpen(!menuOpen)}
              className="menu-toggle mobileonly"
              aria-expanded={menuOpen ? "true" : "false"}
              aria-label="Meny"
            >
              <img src="./images/ham.svg" alt="" aria-hidden="true" />
            </button>
            <div
              className={cls("navels", "main-menu", { open: menuOpen })}
              id="main-menu"
            >
              <nav aria-label="Huvudmeny">
                <ul onClick={() => setMenuOpen(false)}>
                  {mainlinks.map(({ id, title, href }) => (
                    <li key={id}>
                      <a
                        className={cls("mainlinks", {
                          selected: selectedLink === id,
                        })}
                        href={href}
                        {...(selectedLink === id
                          ? { "aria-current": "page" }
                          : {})}
                      >
                        {title}
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
            <div
              onClick={() => setMenuOpen(false)}
              className="backdrop mobileonly"
              aria-hidden="true"
              hidden
            ></div>

            <a
              href="https://www.accessibilitycloud.com/"
              target="_blank"
              style={{ display: "flex" }}
            >
              <img
                src="./images/ac3.svg"
                alt="Accessibility Cloud logo"
                style={{
                  height: 56,
                }}
              />
            </a>
          </div>
        </div>
      </div>
    </header>
  );
}
