import TestTable from "../home/testtable";
import Footer from "../shared/footer";
import Sticky from "../shared/sticky";
// import ContactForm from '../shared/contactform';
export default function TestMetodDetail() {
  return (
    <>
      <Sticky selectedLink="testmetod" />

      <main>
        <div className="wider">
          <div className="testmetodcontent">
            <div className="detailheader">
              {/* <Link to="/" className="backbtn" >
                                <span>Startsidan</span>
                            </Link> */}

              <h1>Testmetod</h1>
            </div>

            <TestTable />
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
