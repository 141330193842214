export default function Footer () {
    return (
        <footer>
            <div className="wider">
                <div className="footercontent">
                    <div className="copy">© 2000 - 2023 Funka Nu AB.</div>

                    <div className="links">
                        <a href="https://www.accessibilitycloud.com/" target="_blank">Accessibility Cloud <img src="./images/open-new.svg" alt="Öppnar en ny flik" /></a>
                        <a href="https://www.funka.com/" target="_blank">Funkas webbplats <img src="./images/open-new.svg" alt="Öppnar en ny flik" /></a>
                        <a href="https://www.funka.com/om-funka/om-webbplatsen/personuppgiftpolicy/" target="_blank" rel="noreferrer">Personuppgiftpolicy <img src="./images/open-new.svg" alt="Öppnar en ny flik" /> </a>
                    </div>

                </div>
                
            </div>
        </footer>
    );
}