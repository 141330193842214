import Footer from '../shared/footer';
// import Header from '../shared/header';
import Sticky from '../shared/sticky';
import { Link } from 'react-router-dom';
// import ContactForm from '../shared/contactform';

export default function WhoNeeds() {

    return (
        <>
            {/* <Header /> */}

            <Sticky selectedLink="vemberors" />
<main>
    
                <div className="wider"  >
                    <div className="testmetodcontent">
                        <div className="detailheader" id="main-content">
                            {/* <Link to="/" className="backbtn">
                                <span>Startsidan</span>
                            </Link> */}
    
                            <h1>Vem berörs av tillgängligheten?</h1>
                        </div>
    
                        <section id="wnacontent">
    
                            <div className="standardbody wnacontent" >
    <div className="parawider">
        
                                    <div className="altersec">
                                        <div className="text">
                                            <p>
                                                Enligt WHO är det ca 15% av befolkning som har en funktionsnedsättning. Troligtvis är det fler. Det finns
                                                sannolikt ett mörkertal, där personer till exempel inte fått någon diagnos. Det är också svårt att få en överblick
                                                över antalet individer med olika funktionsnedsättningar, eftersom vi av integritetsskäl inte får registrera det.
                                            </p>
                
                                            <p>Det finns också flera användare som inte ingår i statistiken men som påverkas av tillgängligheten, eller snarare bristen på tillgänglighet, på en webbplats:</p>
                
                                            <ul>
                                                <li>Mobilanvändare</li>
                                                <li>Användare med annat modersmål</li>
                                                <li>Äldre</li>
                                                <li>Försiktiga, okunniga eller tekniskt ointresserade användare</li>
                                            </ul>
                                        </div>
                                        <img src="./images/figurine-05.svg" alt="En man håller i en telefon men tittar åt det andra hållet, illustration."  />
                                    </div>
        
        
                                    <div className="altersec">
                                        <div>
                                            <h2>Permanent, temporär eller situationsberoende funktionsnedsättning</h2>       
                             
                
                                            <p>
                                                En funktionsnedsättning kan vara permanent, tillfällig eller situationsberoende. Du kan till exempel vara född med en
                                                grav synnedsättning. Du kan ha en tillfällig skada på hornhinnan som gör att du behöver använda ögonlapp på ett öga och
                                                därmed inte ser så bra under en period. Du kan befinna dig i en situation som påverkar din förmåga att se på en
                                                mobilskärm, till exempel ute i starkt solljus. Tillgänglighet är alltså relevant för alla användare. Förbättrar vi för
                                                de som har störst svårigheter så blir det bättre för alla.
                                            </p>
                                        </div>
                                        <img src="./images/SVG/wna-01.png" alt="Tre personer i olika situationer. Ena har glasögon och vit käpp, den andra har ett öga täckt av en ögonlapp och den tredje står i mycket starkt solljus. Illustrationer."  />
                                    </div>



        
                                    <div className="altersec">
                                        <div className="text">
                                            <h2>Synnedsättning </h2>
                            
                
                                            <p>
                                                En synskada kan antigen innebära att du ser dåligt eller inte ser alls (är blind), ofta så har du en viss förmåga att
                                                se i ljus så vi brukar prata om en grav synnedsättning. Du kan se dåligt på många olika sätt. Suddighet, avgränsat
                                                synfält och problem med färgseende är vanliga problem. Det finns många olika typer av synskador. När vi blir äldre ser
                                                många av oss allt sämre.
                                            </p>
                                            <h3>Din syn kan också påverkas av:</h3>
                                    <ul>
                                        <li>Dåliga ljusförhållanden</li>
                                        <li>Långa avstånd</li>
                                        <li>För liten text</li>
                                        <li>Dåliga kontraster</li>
                                    </ul>
                                        </div>
                                        <img src="./images/SVG/se.svg" alt="Ett öga, illustration." />
                                    </div>
                                    
        
        
                                    
                                           <div className="altersec">
                                                <div>
                                                    <h2>Blind, grav synnedsättning</h2>
                        
                                                    <p>
                                                        En blind användare eller en användare med grav synnedsättning kan visuellt inte ta del av information på en webbplats
                                                        utan använder skärmläsare och punktskrift. Skärmläsaren läser upp det som finns på skärmen så som text, bilder, knappar
                                                        och länkar. För att skärmläsaren ska presentera informationen så krävs det att webbplatsen har en god teknisk grund, och att kodstandarder följs.
                                                    </p>
                                                </div>
                                                <img src="./images/SVG/blind.svg" alt="En person med glasögon vit käpp, illustration." />
                                           </div>
                                <div className="altersec">
                                        <div>
                                            <h2>Nedsatt syn</h2>
                
                                            <p>
                                                För en användare med nedsatt syn så är det till exempel viktigt med goda kontraster och textstorlek på webbplatsen.
                                                Användare med nedsatt syn kan använda sig av förstorande hjälpmedel, zooma gränssnittet eller förstora texten.
                                                Användaren kan även använda sig av skärmläsare för att presentera innehållet.
                                            </p>
                                            <p>
                                                Det är viktigt att du tänker på att inte göra informationen på webbplatsen beroende av färg, till exempel gällande
                                                diagram, länkar och felmeddelande. En person som har nedsatt färgseende eller är färgblind kommer att ha svårt att ta
                                                del av informationen om den endast ges i färg.
                                            </p>
                                        </div>
                                        <img src="./images/SVG/zoom.svg" alt="Ett förstoringsglas, illustration."  />
                                        
                                    </div>
        
                                    <div className="altersec">
                                        <div>
                                            <h2>Motoriska nedsättningar</h2>
                                 
                
                                            <p>
                                                Rörelsehindrade är stor grupp när det gäller personer med funktionsnedsättning. Här skiljer man mellan medfödda och
                                                förvärvade hinder. Här finns många olika typer av problem, från helt eller delvis förlamande personer till personer som
                                                har darrningar eller är greppsvaga.
                                            </p>
                                            <p>
                                                Det finns många sätt att navigera på i ett gränssnitt. En användare kan till exempel använda ögonstyrning, styra med
                                                munnen, switchar, muspekare, tangentbord och pekskärm. Det finns olika typer av anpassade tangentbord och möss. Många
                                                hjälpmedel bygger på tangentbordsstyrning, och det är därför en hörnpelare inom tillgänglighet att tangentbordsstyrning
                                                fungerar. Det betyder att interaktiva objekt i gränssnittet ska gå att nå och aktivera med ett tangentbord.
                                            </p>
                                        </div>
                                        <img src="./images/SVG/rullstol.svg" alt="En person i rullstol, illustration." />
                                    </div>
                                    <div className="altersec">
                                        <div>
                                            <h2>Kognitiva nedsättningar</h2>
                          
                                            <p>
                                                Kognition handlar om människans sätt att ta in, bearbeta och hantera information. Kognitiva funktioner kan till exempel
                                                vara minne, uppmärksamhet, språk, beslutsfattande, problemlösning, snabbhet, organisering, koncentration och
                                                simultanförmåga. Gruppen är bredd och det finns stor variation.
                                            </p>
                                        </div>
                                        <img src="./images/SVG/kognition.svg" alt="En hjärna, illustration. "   />
                                    </div>
        
                                   <div className="altersec">
                                        <div>
                                            <h2>Neuropsykiatriska funktionsnedsättningar</h2>
                
                                            <p>
                                                Neuropsykiatriska funktionsnedsättningar, som ADHD och Autism, kan till exempel påverka en persons förmåga att komma
                                                ihåg saker eller att koncentrera sig. Demens leder till gradvis funktionsnedsättning och ökade problem.
                                            </p>
                                            <h3>Andra orsaker kan vara:</h3>
                                            <ul>
                                                <li>Smärta</li>
                                                <li>Trötthet och dålig sömn</li>
                                                <li>Medicinering</li>
                                                <li>Stress och utmattning</li>
                
                                            </ul>
                                        </div>
                                        <img src="./images/SVG/tala.svg" alt="En mun, illustration."  />
                                   </div>
        
        
                                    <div className="altersec">
                                        <div>
                                            <h2>Förståelse</h2>
                
                                            <p>
                                                Utvecklingsstörning innebär att en person har en begränsad förmåga att förstå, nedsättningen kan vara lindrig, måttlig
                                                eller svår. Även svårighetsgrad, bristande kunskaper eller ovana vid ett visst sammanhang kan skapa problem att förstå.
                                            </p>
                
                                            <h3>Andra orsaker som påverkar förmågan att förstå kan vara:</h3>
                                            <ul>
                                                <li>Dålig skolgång</li>
                                                <li>Stress och trötthet</li>
                                                <li>Mediciner</li>
                                                <li>Hjärnskador</li>
                                                <li>Missbruk</li>
                                            </ul>
                                            <p>
                                                Till exempel är en konsekvent och avskalad utformning, inga rörelser och distraktioner i gränssnittet och återkoppling
                                                viktigt för förståelsen.
                                            </p>
                                        </div>
                                        <img src="./images/SVG/lasning.svg" alt="Bokstaven T och flera raka linjer brevid, illustration."   />
                                    </div>
        
        
                                    
        
                                    <div className="altersec">
                                        <div>
                                            <h2>Läs- och skrivsvårigheter</h2>
                
                                            <p>
                                                Dyslexi är en vanlig orsak till läs- och skrivsvårigheter. Då har du svårt att avkoda bokstäverna men det har inget med
                                                begåvning att göra. Afasi är en orsak till att vuxna tappar sin läsförmåga helt eller delvis.
                                            </p>
                
                                            <h3>Andra orsaker kan vara:</h3>
                
                                            <ul>
                                                <li>Dålig skolgång</li>
                                                <li>Annat modersmål</li>
                                                <li>Text med för hög svårighetsgrad</li>
                                                <li>Hjärnskador och sjukdomar i hjärnan</li>
                                                <li>Neurologiska och reumatiska sjukdomar</li>
                                                <li>Begåvningshandikapp</li>
                                            </ul>
                
                                            <p>
                                                För att underlätta för användare så är det viktigt att text är enkel och begriplig, att du tänker på textens struktur
                                                och presentation, och använder bilder. Hjälpmedel kan vara uppläsande hjälpmedel, läslinjal och röststyrning.
                                            </p>
                                        </div>
                                        <img src="./images/SVG/dyslexi.svg" alt="En person som läser en bok, illustration."  />
                                        
                                    </div>
        
    </div>
                            </div>
                        </section>
    
                    </div>
                </div>
    

</main>   
            {/* <ContactForm /> */}
            <Footer />
        </>
    );
}


